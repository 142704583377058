import { State } from 'src/redux';

import { isLoading } from 'src/services/api';
import { UserRole } from 'src/services/users';

export const getAuth = (state: State) => state.auth;

export const getAuthToken = (state: State) => state.auth.token;

export const getCurrentUser = (state: State) => state.auth.userInfo;

export const getIsAdmin = (state: State) => {
  // check since old users wont have this
  if (state.auth.userInfo) {
    if (!state.auth.userInfo.app_roles) {
      return false;
    }
    const isAdmin = state.auth.userInfo.app_roles.find(
      (role) => role.name === 'dashboard' && role.role === UserRole.Admin,
    );
    return !!isAdmin;
  }
  return false;
};

export const getIsAuthLoading = (state: State) => isLoading(getAuth(state));

export const getToken = (state: State) => state.auth.token;

import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

export const DashboardUtility: React.FC<any> = ({ item }: { item: any }) => {
  const [content, setContent] = useState('');

  useEffect(() => {
    fetch(item.markdownSrc)
      .then((res) => res.text())
      .then((md) => {
        return setContent(md);
      })
      .catch(() => {});
  }, [item.markdownSrc]);

  return (
    <a
      href={item.url}
      key={item.name}
      target="_blank"
      rel="noreferrer"
      className="bg-white rounded-lg overflow-hidden sm:p-2 flex items-center group"
    >
      <div className="w-16 h-16 flex items-center justify-center bg-primary-100 group-hover:bg-primary-200 transition-colors rounded-lg mr-4">
        {item.icon && <item.icon className="h-6 w-6 text-primary-900" aria-hidden="true" />}
        {item.assetSrc && <img className="h-6 w-6" src={item.assetSrc} alt={item.name} />}
      </div>
      <div>
        <dt className="truncate text-sm leading-5 font-medium">{item.name}</dt>
        <dd className="mt-1 text-gray-500 text-sm leading-5 font-normal">
          <ReactMarkdown>{content}</ReactMarkdown>
        </dd>
      </div>
    </a>
  );
};

import React, { useState, useEffect } from 'react';
import { Modal } from 'src/components';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import matter from 'gray-matter';
import { QuestionMarkCircleIcon, ExternalLinkIcon } from '@heroicons/react/outline';

type DashboardCardProps = {
  app: any;
  version: string | number;
};

export const DashboardCard = ({ app, version }: DashboardCardProps) => {
  const [readMoreVisible, setReadMoreVisible] = useState(false);
  const [rawMarkdown, setContent] = useState('');

  const onReadMoreToggleClick = () => setReadMoreVisible((current) => !current);

  useEffect(() => {
    fetch(app.markdownSrc)
      .then((res) => res.text())
      .then((md) => {
        return setContent(md);
      })
      .catch(() => {});
  }, [app.markdownSrc]);

  const appDescription = matter(rawMarkdown);

  // Check if we're actually pulling markdown and if not, replace with default boilerplate file

  const isItMarkdown = appDescription.content.search('DOCTYPE');

  if (isItMarkdown !== -1) {
    fetch('/custom/markdown/default.md')
      .then((res) => res.text())
      .then((md) => {
        return setContent(md);
      })
      .catch(() => {});
  }

  const launchButton = app.external ? (
    <>
      <span>Go To App</span>
      <ExternalLinkIcon className="w-4 h-4 ml-1" />
    </>
  ) : (
    <>
      <span>Go to App</span>
      <img className="h-6" src="/assets/stackspin_white_logo_icon.svg" alt="Stackspin" />
    </>
  );

  return (
    <>
      <div
        className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-100 mb-4 md:mb-0 flex flex-col justify-between relative"
        key={app.name}
      >
        <div className="upper-half">
          <div className="flex justify-between">
            {!app.external ? (
              // Core tag is invisible for the time being. I am leaving it in here
              // because we might want to re-use this ternary for a different tag, such as "custom"
              <span className="inline-flex invisible ml-3 items-center rounded-b-md bg-primary-100 px-2 py-1 text-xs font-medium text-primary-800 border border-t-0 ">
                Core
              </span>
            ) : (
              <span className="inline-flex ml-3 items-center rounded-b-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600 border border-t-0 ">
                External
              </span>
            )}

            {isItMarkdown === -1 ? (
              <a href="#" onClick={onReadMoreToggleClick} className="inline-flex items-center justify-center mr-2">
                <QuestionMarkCircleIcon className="h-4 w-4 text-primary-600 hover:text-primary-800" />
              </a>
            ) : null}
          </div>
          <div className="px-4 pt-4 pb-2">
            <div className="mr-4 flex items-center">
              <img
                className="h-16 w-16 rounded-md overflow-hidden mr-4 flex-shrink-0"
                src={app.assetSrc}
                onError={(e) => {
                  e.currentTarget.src = 'custom/assets/default.svg';
                }}
                alt={app.name}
              />

              <div className="flex justify-between gap-1 items-center">
                <h2 className="text-xl leading-8 font-bold">{app.name}</h2>
                <p className="text-xs text-gray-400">{version}</p>
              </div>
            </div>

            <p className="text-gray-500 mt-2 text-sm leading-5 font-normal">{appDescription.data.tileExcerpt}</p>
          </div>
        </div>
        <div className="px-2.5 py-2.5 sm:px-4 flex justify-end">
          <a
            href={app.slug === 'wordpress' ? `${app.url}/wp-admin/` : app.url}
            target="_blank"
            rel="noreferrer"
            className="inline-flex h-10 items-center px-2.5 py-1.5 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          >
            {launchButton}
          </a>
        </div>
      </div>

      <Modal open={readMoreVisible} onClose={onReadMoreToggleClick} title={app.name} img={app.assetSrc}>
        <ReactMarkdown className="prose prose-slate" remarkPlugins={[remarkGfm]}>
          {appDescription.content}
        </ReactMarkdown>
      </Modal>
    </>
  );
};

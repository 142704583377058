import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthToken, getCurrentUser, getIsAdmin, signIn, signOut } from '../redux';

export function useAuth() {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const authToken = useSelector(getAuthToken);
  const isAdmin = useSelector(getIsAdmin);

  const logIn = useCallback(
    (params) => {
      return dispatch(signIn(params));
    },
    [dispatch],
  );

  const logOut = useCallback(() => {
    return dispatch(signOut());
  }, [dispatch]);

  return {
    authToken,
    currentUser,
    isAdmin,
    logIn,
    logOut,
  };
}

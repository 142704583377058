import React from 'react';
import GaugeComponent from 'react-gauge-component';

import { Resources } from 'src/services/resources';

type ResourceCardProps = {
  metric: any;
  resources: Resources;
};

const format = (id: string) => {
  switch (id) {
    case 'disk':
      return (s: number) => `${s.toFixed(1)} GB`;
    case 'memory':
      return (s: number) => `${s.toFixed(1)} GB`;
    case 'cpu':
      return (s: number) => `${s.toFixed(0)}%`;
  }
};
const formatTick = (id: string) => {
  switch (id) {
    case 'disk':
      return (s: number) => `${s.toFixed(1)}`;
    case 'memory':
      return (s: number) => `${s.toFixed(1)}`;
    case 'cpu':
      return (s: number) => `${s.toFixed(0)}%`;
  }
};

const getValue = (id: string, resources: Resources) => {
  switch (id) {
    case 'disk':
      return resources.disk_used;
    case 'memory':
      return resources.memory_used;
    case 'cpu':
      return resources.cpu;
  }
};

const getMax = (id: string, resources: Resources) => {
  switch (id) {
    case 'disk':
      return resources.disk_total;
    case 'memory':
      return resources.memory_total;
    case 'cpu':
      return 100;
    default:
      return 100;
  }
};

export const ResourceCard = ({ metric, resources }: ResourceCardProps) => {
  const max = getMax(metric.id, resources);
  return (
    <>
      <div
        className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-100 mb-4 md:mb-0 flex flex-col justify-between relative"
        key={metric.id}
      >
        <div className="px-4 pt-4 pb-2">
          <div className="mr-4 flex items-center">
            <div className="flex flex-col justify-between gap-1 items-center">
              <h2 className="text-xl leading-8 font-bold">{metric.title}</h2>
              <GaugeComponent
                value={getValue(metric.id, resources)}
                marginInPercent={{
                  top: 0.03,
                  bottom: 0.03,
                  left: 0.15,
                  right: 0.15,
                }}
                maxValue={max}
                arc={{
                  subArcs: [
                    { limit: max * 0.7, color: 'green' },
                    { limit: max * 0.85, color: 'orange' },
                    { limit: max, color: 'red' },
                  ],
                }}
                labels={{
                  valueLabel: {
                    formatTextValue: format(metric.id),
                    style: {
                      fill: 'black',
                      textShadow: 'none',
                    },
                  },
                  tickLabels: {
                    defaultTickValueConfig: {
                      formatTextValue: formatTick(metric.id),
                    },
                  },
                }}
                style={{
                  width: '350px',
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

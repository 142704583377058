import { Dispatch } from 'redux';
import { performApiCall } from 'src/services/api';
import { transformResources } from '../transformations';

export enum ResourcesActionTypes {
  FETCH_RESOURCES = 'resources/fetch_resources',
}

export const fetchResources = () => async (dispatch: Dispatch<any>) => {
  try {
    const { data } = await performApiCall({
      path: '/resources',
      method: 'GET',
    });

    dispatch({
      type: ResourcesActionTypes.FETCH_RESOURCES,
      payload: transformResources(data),
    });
  } catch (err) {
    console.error(err);
  }
};

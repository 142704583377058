import { Dispatch } from 'redux';
import { performApiCall } from 'src/services/api';
// import { transformSysInfo } from '../transformations';

export enum SysInfoActionTypes {
  FETCH_SYSINFO = 'info',
}

export const fetchSysInfo = () => async (dispatch: Dispatch<any>) => {
  try {
    const { data } = await performApiCall({
      path: '/info',
      method: 'GET',
    });

    dispatch({
      type: SysInfoActionTypes.FETCH_SYSINFO,
      payload: data,
    });
  } catch (err) {
    console.error(err);
  }
};

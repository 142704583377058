import React from 'react';
import { Popover, Transition } from '@headlessui/react';
import {
  CheckCircleIcon,
  NewspaperIcon,
  ChevronDownIcon,
  CalendarIcon,
  BeakerIcon,
  HomeIcon,
  RefreshIcon,
} from '@heroicons/react/outline';
import { SysInfoState } from 'src/services/sysInfo/redux/types';

export const VersionInfo = (sysInfo: SysInfoState) => {
  const branch = sysInfo.sysInfo.followingGit;
  const updateTime: Date = new Date(sysInfo.sysInfo.lastUpdated);

  const branchInfoAvailable = typeof branch !== 'undefined';
  const stableBranch = branchInfoAvailable ? branch.match(/^v(\d)+$/) : false;

  const versionInfo = stableBranch ? (
    <>
      <CheckCircleIcon className="h-4 w-4 text-primary-600" />
      <span>Stackspin v{sysInfo.sysInfo.version}</span>
    </>
  ) : branchInfoAvailable ? (
    <>
      <span>Stackspin v{sysInfo.sysInfo.version}</span>
    </>
  ) : null;

  const updatesText = stableBranch ? (
    <>
      <div className="px-4 py-2 flex items-center gap-1">
        <HomeIcon className="w-4 h-4 text-gray-500" />
        <span>Latest stable branch ({branch})</span>
      </div>
      <div className="px-4 py-2 flex items-center gap-1">
        <RefreshIcon className="w-4 h-4 text-gray-500" />
        <span>Automatic updates active</span>
      </div>
    </>
  ) : branchInfoAvailable ? (
    <div className="px-4 py-2 flex items-center gap-1">
      <BeakerIcon className="w-4 h-4 text-gray-500" />
      <span>Custom branch ({branch})</span>
    </div>
  ) : null;

  return (
    <Popover className="relative flex items-center">
      <Popover.Button className="flex text-sm px-2.5 py-1.5 group items-center gap-1 border border-transparent hover:shadow-sm focus:shadow-sm font-medium rounded-md text-gray-500 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
        {versionInfo}
        <ChevronDownIcon className="h-4 w-4 text-primary-800" />
      </Popover.Button>
      <Transition
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        className="z-10"
      >
        <Popover.Panel className="absolute z-10 top-5 mt-2 right-0 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="flex flex-col items-stretch p-4 w-60 divide-y divide-gray-100 text-xs text-gray-500">
            {updatesText}
            <div className="px-4 py-2 flex items-center gap-1">
              <NewspaperIcon className="h-4 w-4 text-gray-500" />
              <a
                className="hover:text-primary-500 underline"
                href={sysInfo.sysInfo.releaseNotesUrl}
                target="_blank"
                rel="noreferrer noopener"
              >
                Changelog
              </a>
            </div>
            <div className="px-4 py-2 flex items-center gap-1">
              <CalendarIcon className="h-4 w-4 text-gray-500" />
              <span>Last update:</span>
              <span>
                {/* {updateTime.getDate()}.{updateTime.getMonth()} {updateTime.getFullYear()} */}
                {updateTime.toLocaleDateString('en-uk', { day: 'numeric', year: 'numeric', month: 'short' })}
              </span>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

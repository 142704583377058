import React from 'react';
import { SpeakerphoneIcon } from '@heroicons/react/outline';

type BannerProps = {
  title: string;
  titleSm: string;
};

const Banner: React.FC<BannerProps> = ({ title, titleSm }) => {
  return (
    <div className="bg-primary-600 rounded-md">
      <div className="max-w-7xl mx-auto py-3 px-3 sm:px-3">
        <div className="flex items-center justify-between flex-wrap">
          <div className="w-0 flex-1 flex items-center">
            <span className="flex p-2 rounded-lg bg-primary-800">
              <SpeakerphoneIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </span>
            <p className="ml-3 font-small text-white truncate">
              <span className="md:hidden">{titleSm}</span>
              <span className="hidden md:inline">{title}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;

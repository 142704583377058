import { useDispatch, useSelector } from 'react-redux';
import { fetchResources } from '../redux';
import { getResources } from '../redux/selectors';

export function useResources() {
  const dispatch = useDispatch();
  const resources = useSelector(getResources);

  function loadResources() {
    return dispatch(fetchResources());
  }

  return {
    resources,
    loadResources,
  };
}

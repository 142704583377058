import { useDispatch, useSelector } from 'react-redux';
import { fetchApps, fetchAppBySlug, updateApp, installAppBySlug, clearCurrentApp, deleteApp } from '../redux';
import { getCurrentApp, getAppLoading, getAppsLoading, getApps } from '../redux/selectors';

export function useApps() {
  const dispatch = useDispatch();
  const apps = useSelector(getApps);
  const app = useSelector(getCurrentApp);
  const appLoading = useSelector(getAppLoading);
  const appTableLoading = useSelector(getAppsLoading);

  function loadApps() {
    return dispatch(fetchApps());
  }

  function loadApp(slug: string) {
    return dispatch(fetchAppBySlug(slug));
  }

  function editApp(data: any) {
    return dispatch(updateApp(data));
  }

  function installApp(data: any) {
    return dispatch(installAppBySlug(data));
  }

  function disableApp(data: any) {
    return dispatch(deleteApp(data));
  }

  function clearSelectedApp() {
    return dispatch(clearCurrentApp());
  }

  return {
    apps,
    app,
    loadApp,
    loadApps,
    editApp,
    appLoading,
    appTableLoading,
    installApp,
    disableApp,
    clearSelectedApp,
  };
}

import { User } from '../users';
import { Auth } from './types';
import { transformAppRoles } from '../users/transformations';

const transformUser = (response: any): User => {
  return {
    id: response.id ?? '',
    app_roles: response.app_roles ? response.app_roles.map(transformAppRoles) : [],
    email: response.email ?? '',
    name: response.name ?? '',
    preferredUsername: response.preferredUsername ?? '',
    status: response.state ?? '',
  };
};

export const transformAuthUser = (response: any): Auth => {
  return {
    token: response.accessToken,
    userInfo: response.userInfo ? transformUser(response.userInfo) : null,
  };
};

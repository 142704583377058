import { AppActionTypes } from './actions';

const initialAppsState: any = {
  apps: [],
  app: {},
  appModalLoading: false,
  appsLoading: false,
};

const appsReducer = (state: any = initialAppsState, action: any) => {
  switch (action.type) {
    case AppActionTypes.FETCH_APPS:
      return {
        ...state,
        apps: action.payload,
      };
    case AppActionTypes.SET_APP_LOADING:
      return {
        ...state,
        appLoading: action.payload,
      };
    case AppActionTypes.SET_APPS_LOADING:
      return {
        ...state,
        appsLoading: action.payload,
      };
    case AppActionTypes.FETCH_APP:
    case AppActionTypes.UPDATE_APP:
    case AppActionTypes.INSTALL_APP:
      return {
        ...state,
        currentApp: action.payload,
      };
    case AppActionTypes.CLEAR_APP:
    case AppActionTypes.DELETE_APP:
      return {
        ...state,
        currentApp: {},
      };
    default:
      return state;
  }
};

export default appsReducer;

import React from 'react';
import { LightningBoltIcon, NewspaperIcon, SparklesIcon } from '@heroicons/react/outline';
import { SysInfoState } from 'src/services/sysInfo/redux/types';

export const UpdateAlert = (sysInfo: SysInfoState) => {
  const updateTime: Date = new Date(sysInfo.sysInfo.lastUpdated);
  const hoursSinceUpdate = Math.floor((new Date().getTime() - updateTime.getTime()) / 3600000);
  const updateStatus: string =
    sysInfo.sysInfo.lastRelease > sysInfo.sysInfo.version ? 'imminent' : hoursSinceUpdate <= 24 ? 'updated' : 'no';

  const updateAlert = (status: string) =>
    status === 'imminent' ? (
      <div className="update-alert w-full md:h-5 h-12 md:py-3 px-5 bg-amber-100 flex items-center justify-center text-xs font-medium text-gray-500 gap-2">
        <LightningBoltIcon className="h-4 w-4 flex-none text-primary-800" />
        <span className="py-3">Attention: your Stackspin instance will be updated in the next 24 hours. </span>
        <a
          className="hover:text-primary-500 underline py-3"
          href={sysInfo.sysInfo.releaseNotesUrl}
          target="_blank"
          rel="noreferrer noopener"
        >
          Explore new features <NewspaperIcon className="h-4 w-4 inline" />
        </a>
      </div>
    ) : status === 'updated' ? (
      <div className="update-alert w-full h-5 py-3 bg-primary-200 flex items-center justify-center text-xs font-medium text-primary-700 gap-2">
        <SparklesIcon className="h-4 w-4 flex-none text-primary-800" />
        <p>Your Stackspin just got an update!</p>
        <a
          className="hover:text-primary-500 underline"
          href={sysInfo.sysInfo.releaseNotesUrl}
          target="_blank"
          rel="noreferrer noopener"
        >
          See what&apos;s new <NewspaperIcon className="h-4 w-4 inline" />
        </a>
      </div>
    ) : null;

  return <>{updateAlert(updateStatus)}</>;
};

import React from 'react';
import { useController } from 'react-hook-form';

/* eslint-disable react/react-in-jsx-scope */
export const Select = ({ control, name, label, options, disabled = false }: SelectProps) => {
  const {
    field,
    // fieldState: { invalid, isTouched, isDirty },
    // formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
  });

  return (
    <>
      {label && (
        <label htmlFor={name} className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </label>
      )}
      <select
        id={name}
        onChange={field.onChange} // send value to hook form
        onBlur={field.onBlur} // notify when input is touched/blur
        value={field.value ? field.value : ''} // input value
        name={name} // send down the input name
        ref={field.ref} // send input ref, so we can focus on input when error appear
        className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
        disabled={disabled}
      >
        {options?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.name}
          </option>
        ))}
      </select>
    </>
  );
};

type SelectProps = {
  control: any;
  name: string;
  label?: string;
  options?: any[];
  disabled?: boolean;
};

import React, { useState } from 'react';
import { TabPanel } from './TabPanel';
import { TabsProps } from './types';

export const Tabs = ({ tabs, onTabClick }: TabsProps) => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  const handleTabPress = (index: number) => () => {
    setActiveTabIndex(index);
    if (onTabClick) {
      onTabClick(index);
    }
  };

  function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md"
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabs.map((tab, tabIndex) => (
            <a
              onClick={handleTabPress(tabIndex)}
              key={tab.name}
              className={classNames(
                activeTabIndex === tabIndex
                  ? 'bg-gray-100 text-gray-700'
                  : 'text-gray-500 hover:text-gray-700 hover:bg-gray-50',
                'px-3 py-2 font-medium text-sm rounded-md cursor-pointer',
              )}
              aria-current={activeTabIndex === tabIndex ? 'page' : undefined}
            >
              {tab.name}
            </a>
          ))}
        </nav>
      </div>

      {tabs.map(({ component, name, disabled }, index) =>
        disabled ? (
          <React.Fragment key={name} />
        ) : (
          <TabPanel key={name} isActive={activeTabIndex === index}>
            {component}
          </TabPanel>
        ),
      )}
    </>
  );
};

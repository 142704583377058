import { performApiCall } from 'src/services/api';

import { User } from './types';
import { transformUser } from './transformations';

export const fetchMemberDetails = async (memberId: number): Promise<User> => {
  const res = await performApiCall({
    path: `/members/${memberId}`,
  });
  return transformUser(res.data);
};

import { InformationCircleIcon } from '@heroicons/react/outline';

export const DASHBOARD_QUICK_ACCESS = [
  {
    name: 'Support',
    url: 'https://docs.stackspin.net',
    markdownSrc: '/markdown/support.md',
    icon: InformationCircleIcon,
  },
];

/** Apps that should not be shown on the dashboard */
export const HIDDEN_APPS = ['dashboard', 'velero'];

/** Apps that should be shown under "Utilities" */
export const UTILITY_APPS = ['monitoring'];

/* eslint-disable react-hooks/exhaustive-deps */
/**
 * Page that shows only installed applications, and links to them.
 *
 * "Utilities" is a special section that links to the Stackspin documentation,
 * and that shows the "Monitoring" application if it is installed.
 */

import React, { useEffect } from 'react';
import { useApps } from 'src/services/apps';
import { useSysInfo } from 'src/services/sysInfo';
import { useAuth } from 'src/services/auth';

import { AppStatusEnum } from 'src/services/apps/types';
import { DashboardCard, DashboardUtility, UpdateAlert, VersionInfo } from './components';
import { DASHBOARD_QUICK_ACCESS, HIDDEN_APPS, UTILITY_APPS } from './consts';

type DashboardProps = {
  isAdmin: boolean;
};

export const Dashboard = ({ isAdmin }: DashboardProps) => {
  const host = window.location.hostname;
  const splitedDomain = host.split('.');
  splitedDomain.shift();
  const { apps, loadApps } = useApps();
  const { sysInfo, loadSysInfo } = useSysInfo();

  // Tell React to load the apps and system information
  useEffect(() => {
    loadApps();
    loadSysInfo();
    return () => {};
  }, []);

  const { currentUser } = useAuth();

  const appVersions = { ...sysInfo.sysInfo.appVersions };

  const greet = () => {
    const safeUserName = currentUser?.name !== '' ? currentUser?.name : currentUser?.email;
    const myDate = new Date();
    const hours = myDate.getHours();
    const greeting =
      hours < 12 ? 'morning' : hours >= 12 && hours <= 17 ? 'afternoon' : hours >= 17 && hours <= 24 ? 'evening' : null;

    return (
      <span>
        Good {greeting}, {safeUserName}.
      </span>
    );
  };

  return (
    <div className="relative">
      <UpdateAlert sysInfo={sysInfo.sysInfo} />
      <div className="max-w-7xl mx-auto py-4 px-3 sm:px-6 lg:px-8">
        <div className="mt-6 pb-5 border-b border-gray-200 flex items-center justify-between">
          <h1 className="text-xl sm:text-3xl leading-6 font-bold text-gray-900">{greet()}</h1>
          <div className="system-status text-xs font-medium text-gray-500 flex flex-col gap-2">
            <div className="flex items-center gap-1">
              <VersionInfo sysInfo={sysInfo.sysInfo} />
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-7xl mx-auto py-4 px-3 sm:px-6 lg:px-8 h-full flex-grow">
        <div className=" mt-5 grid grid-cols-1 md:grid-cols-2 md:gap-4 lg:grid-cols-3 mb-10">
          {apps
            .filter((app) => HIDDEN_APPS.concat(UTILITY_APPS).indexOf(app.slug) === -1)
            .filter((app) => app.status !== AppStatusEnum.NotInstalled)
            // .filter((app) => !app.external)
            .map((app) => {
              const version = isAdmin ? appVersions[app.slug as keyof typeof appVersions] : '';
              return <DashboardCard app={app} key={app.name} version={version} />;
            })}
        </div>
        <div className="max-w-4xl mx-auto py-4 sm:px-6 lg:px-8 h-full flex-grow">
          <div className="pb-4 border-b border-gray-200 sm:flex sm:items-center">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Utilities</h3>
          </div>
          <dl className="mt-5 grid grid-cols-1 gap-2 sm:grid-cols-2">
            {DASHBOARD_QUICK_ACCESS.map((item) => (
              <DashboardUtility item={item} key={item.name} />
            ))}
            {apps
              .filter((app) => UTILITY_APPS.indexOf(app.slug) !== -1 && app.url !== null)
              .filter((app) => app.status !== AppStatusEnum.NotInstalled)
              .map((app) => (
                <DashboardUtility item={app} key={app.name} />
              ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

import { useDispatch, useSelector } from 'react-redux';
import { fetchSysInfo } from '../redux';
import { getSysInfo } from '../redux/selectors';

export function useSysInfo() {
  const dispatch = useDispatch();
  const sysInfo = useSelector(getSysInfo);

  function loadSysInfo() {
    return dispatch(fetchSysInfo());
  }

  return {
    sysInfo,
    loadSysInfo,
  };
}

import { createApiReducer, chainReducers, INITIAL_API_STATUS } from 'src/services/api';

import { User } from 'src/services/users';
import { AuthState } from './types';
import { AuthActionTypes } from './actions';
import { transformAuthUser } from '../transformations';

const initialCurrentUserState: User = {
  email: '',
  name: '',
  id: '',
  app_roles: [],
  status: '',
  preferredUsername: '',
};

const initialState: AuthState = {
  token: null,
  userInfo: initialCurrentUserState,
  _status: INITIAL_API_STATUS,
};

const authLocalReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case AuthActionTypes.UPDATE_AUTH_USER:
      return {
        ...state,
        userInfo: action.payload,
      };
    default:
      return state;
  }
};

const auth = createApiReducer(
  [AuthActionTypes.SIGN_IN_START, AuthActionTypes.SIGN_IN_SUCCESS, AuthActionTypes.SIGN_IN_FAILURE],
  (data) => transformAuthUser(data),
  (data) => data.error.message,
);

const signOut = createApiReducer(
  ['', AuthActionTypes.SIGN_OUT, ''],
  () => initialState,
  () => initialState,
);

export default chainReducers(initialState, auth, signOut, authLocalReducer);

export type ProgressStepsProps = {
  steps: ProgressStepInfo[];
  onNext?: () => void;
  onPrevious?: () => void;
  onStepClick?: (stepId: string) => void;
};

export interface ProgressStepInfo {
  id: string;
  name: string;
  status: ProgressStepStatus;
  component?: React.ReactNode;
  href?: string;
}

export enum ProgressStepStatus {
  Complete = 0,
  Current = 1,
  Upcoming = 2,
}

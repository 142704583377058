import React from 'react';
import { useController } from 'react-hook-form';
import { Switch as HeadlessSwitch } from '@headlessui/react';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}
/* eslint-disable react/react-in-jsx-scope */
export const Switch = ({ control, name, label }: SwitchProps) => {
  const {
    field,
    // fieldState: { invalid, isTouched, isDirty },
    // formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    defaultValue: '',
  });

  return (
    <>
      {label && (
        <label htmlFor={name} className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </label>
      )}
      <HeadlessSwitch
        checked={field.value}
        onChange={field.onChange}
        className={classNames(
          field.value ? 'bg-primary-600' : 'bg-gray-200',
          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            field.value ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
          )}
        />
      </HeadlessSwitch>
    </>
  );
};

type SwitchProps = {
  control: any;
  name: string;
  label?: string;
};

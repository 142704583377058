export interface App {
  id: number;
  name: string;
  slug: string;
  external: boolean;
  status?: AppStatusEnum;
  url: string;
  automaticUpdates: boolean;
  configuration?: string;
  assetSrc?: string;
  markdownSrc?: string;
}

export interface DisableAppForm {
  slug: string;
  removeAppData: boolean;
}

export interface AppStatus {
  installed: boolean;
  ready: boolean;
  message: string;
}

export enum AppStatusEnum {
  NotInstalled = 'Not installed',
  Installed = 'Installed',
  Installing = 'Installing',
  External = 'External',
}

import { ResourcesActionTypes } from './actions';

const initialResourcesState: any = {
  resources: {},
};

const resourcesReducer = (state: any = initialResourcesState, action: any) => {
  switch (action.type) {
    case ResourcesActionTypes.FETCH_RESOURCES:
      return {
        ...state,
        resources: action.payload,
      };
    default:
      return state;
  }
};

export default resourcesReducer;

import React from 'react';
import { useController } from 'react-hook-form';

/* eslint-disable react/react-in-jsx-scope */
export const Checkbox = ({ control, name, label, ...props }: CheckboxProps) => {
  const {
    field,
    // fieldState: { invalid, isTouched, isDirty },
    // formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    defaultValue: false,
  });

  return (
    <>
      {label && (
        <label htmlFor={name} className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </label>
      )}
      <input
        type="checkbox"
        id={name}
        onChange={field.onChange} // send value to hook form
        onBlur={field.onBlur} // notify when input is touched/blur
        checked={field.value}
        name={name} // send down the checkbox name
        className="shadow-sm focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
        {...props}
      />
    </>
  );
};

type CheckboxProps = {
  control: any;
  name: string;
  id?: string;
  label?: string;
  className?: string;
};

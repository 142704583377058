import React from 'react';
import { highlight, languages } from 'prismjs';
import { useController } from 'react-hook-form';
import Editor from 'react-simple-code-editor';

/* eslint-disable react/react-in-jsx-scope */
export const CodeEditor = ({ control, name, required, disabled = false }: CodeEditorProps) => {
  const {
    field,
    // fieldState: { invalid, isTouched, isDirty },
    // formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    rules: { required },
    defaultValue: '',
  });

  return (
    <>
      <Editor
        value={field.value}
        onValueChange={field.onChange}
        highlight={(value) => highlight(value, languages.js, 'yaml')}
        preClassName="font-mono whitespace-normal font-light"
        textareaClassName="font-mono overflow-auto font-light"
        className="font-mono text-sm font-light"
        disabled={disabled}
      />
    </>
  );
};

type CodeEditorProps = {
  control: any;
  name: string;
  required?: boolean;
  disabled?: boolean;
};

import { Resources } from './types';

export const transformResources = (response: any): Resources | null => {
  if (!response.success) {
    return null;
  }
  return {
    cpu: (response.cpu / response.cpu_total) * 100,
    memory_used: (response.memory_total - response.memory_available) / 1e9,
    memory_total: response.memory_total / 1e9,
    disk_used: (response.disk_total - response.disk_free) / 1e9,
    disk_total: response.disk_total / 1e9,
  };
};

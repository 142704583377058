import React from 'react';
import _ from 'lodash';
import Editor from 'react-simple-code-editor';
// import { Menu, Transition } from '@headlessui/react';
// import { ChevronDownIcon } from '@heroicons/react/solid';
import { parse } from 'yaml';
import { highlight, languages } from 'prismjs';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-yaml';
import 'prismjs/themes/prism.css';
import { showToast, ToastType } from 'src/common/util/show-toast';
import { useApps } from 'src/services/apps';
import { initialEditorYaml } from '../../consts';

export const AdvancedTab = () => {
  const [code, setCode] = React.useState(initialEditorYaml);
  const { app, editApp } = useApps();

  const resetCode = () => {
    setCode(initialEditorYaml);
    showToast('Code was reset.');
  };

  const isConfigurationValid = () => {
    try {
      parse(code);
      return true;
    } catch (e: any) {
      return false;
    }
  };

  const verifyCode = () => {
    if (isConfigurationValid()) {
      showToast('Configuration is valid.', ToastType.Success);
    } else {
      showToast('Configuration is not valid! Please fix configuration issues and try again.', ToastType.Error);
    }
  };

  const saveChanges = () => {
    if (isConfigurationValid()) {
      editApp({ ...app, configuration: code });
      return;
    }
    showToast('Configuration is not valid! Please fix configuration issues and try again.', ToastType.Error);
  };

  return (
    <>
      <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between mb-5">
        <h1 className="text-2xl leading-6 font-medium text-gray-900">Configuration</h1>
      </div>
      <div className="grid grid-flow-col grid-cols-2 gap-8">
        <div className="bg-gray-100 overflow-hidden rounded-lg">
          <div className="px-4 h-16 sm:px-6 bg-gray-200 flex items-center">
            <span className="text-gray-600 text-lg leading-6 font-medium">Current Configuration</span>
          </div>
          <div className="px-4 py-5 sm:p-6 overflow-x-auto">
            <Editor
              value={initialEditorYaml}
              onValueChange={_.noop}
              highlight={(value) => highlight(value, languages.js, 'yaml')}
              preClassName="font-mono text-sm font-light"
              textareaClassName="font-mono overflow-auto font-light"
              className="font-mono text-sm font-light"
              disabled
            />
          </div>
        </div>
        <div className="overflow-hidden rounded-lg">
          {/* <div className="px-4 h-16 sm:px-6 bg-gray-200 flex justify-between items-center rounded-t-lg">
              <span className="text-gray-600 text-lg leading-6 font-medium">Edit Configuration</span>

              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary-500">
                    Versions
                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'block px-4 py-2 text-sm',
                            )}
                          >
                            Save Version
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'block px-4 py-2 text-sm',
                            )}
                          >
                            View Version History
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'block px-4 py-2 text-sm',
                            )}
                          >
                            Restore Current Version
                          </a>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div> */}
          <div className="px-4 h-16 sm:px-6 bg-gray-200 flex items-center">
            <span className="text-gray-600 text-lg leading-6 font-medium">Edit Configuration</span>
          </div>
          <div className="px-4 py-5 sm:p-6 border border-t-0 border-gray-200">
            <Editor
              value={code}
              onValueChange={(value) => setCode(value)}
              highlight={(value) => highlight(value, languages.js, 'yaml')}
              preClassName="font-mono whitespace-normal font-light"
              textareaClassName="font-mono overflow-auto font-light"
              className="font-mono text-sm font-light"
            />
          </div>
        </div>
      </div>
      <div className="flex justify-end mt-10">
        <button
          type="button"
          onClick={resetCode}
          className="mr-3 inline-flex items-center px-4 py-2 border border-gray-200 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          Reset
        </button>

        <button
          type="button"
          onClick={verifyCode}
          className="mr-3 inline-flex items-center px-4 py-2 shadow-sm text-sm font-medium rounded-md text-primary-700 bg-primary-100 hover:bg-primary-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          Verify
        </button>

        <button
          type="button"
          onClick={saveChanges}
          className="inline-flex items-center px-4 py-2 shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          Save changes
        </button>
      </div>
    </>
  );
};

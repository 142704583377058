import { SysInfoActionTypes } from './actions';

const initialSysInfoState: any = {
  sysInfo: {},
};

const sysInfoReducer = (state: any = initialSysInfoState, action: any) => {
  switch (action.type) {
    case SysInfoActionTypes.FETCH_SYSINFO:
      return {
        ...state,
        sysInfo: action.payload,
      };
    default:
      return state;
  }
};

export default sysInfoReducer;

import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { Banner, ConfirmationModal, Modal } from 'src/components';
import { Select } from 'src/components/Form';
import { User, UserRole, MultiEditUser, useUsers, NoChange } from 'src/services/users';
import { useAuth } from 'src/services/auth';
import { AppStatusEnum } from 'src/services/apps/types';

import { HIDDEN_APPS } from 'src/modules/dashboard/consts';
// import { initialUserForm } from './consts';
import { TrashIcon } from '@heroicons/react/outline';

import { MultiEditUserModalProps } from './types';

export const MultiEditUserModal = ({ open, onClose, userIds, setUserId, apps }: MultiEditUserModalProps) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [isAdminRoleSelected, setAdminRoleSelected] = useState(true);
  const {
    user,
    editUserById,
    deleteUserById,
    // editMultipleUsers,
    userModalLoading,
    clearSelectedUser,
  } = useUsers();
  const { currentUser, isAdmin } = useAuth();

  // Extending the app list with "No Change" value, so that
  // there is a sane default selection
  // when doing multi-user edits

  interface AppListInt {
    name: string;
    role: UserRole | NoChange;
  }
  const appList: AppListInt[] = [];
  const initialAppRoleLatest = () => {
    apps
      .filter((app) => app.status !== AppStatusEnum.NotInstalled)
      .map((app) => appList.push({ name: app.slug, role: NoChange.NoChange }));
  };
  initialAppRoleLatest();

  const userIdsList: string[] = [];
  const populateUserIdsList = () => {
    userIds.map((id: any) => userIdsList.push(id.original.id));
  };
  populateUserIdsList();

  const userNamesList: string[] = [];
  const populateUserNamesList = () => {
    userIds.map((id: any) => userNamesList.push(id.original.name));
  };
  populateUserNamesList();

  const userEmailsList: string[] = [];
  const populateUserEmailsList = () => {
    userIds.map((id: any) => userEmailsList.push(id.original.email));
  };
  populateUserEmailsList();

  const initialUserForm = {
    userEmails: userEmailsList,
    userIds: userIdsList,
    app_roles: appList,
    userNames: userNamesList,
  };

  // populate the initial "New User" window with installed apps and default roles
  const { control, reset, handleSubmit } = useForm<MultiEditUser>({
    defaultValues: initialUserForm,
  });

  const { fields, update } = useFieldArray({
    control,
    name: 'app_roles',
  });

  useEffect(() => {
    if (!_.isEmpty(user)) {
      reset(user);
    }
  }, [user, reset, open]);

  const dashboardRole = useWatch({
    control,
    name: 'app_roles.0.role',
  });

  useEffect(() => {
    const isAdminDashboardRoleSelected = dashboardRole === UserRole.Admin;
    setAdminRoleSelected(isAdminDashboardRoleSelected);
    if (isAdminDashboardRoleSelected) {
      fields.forEach((field, index) => update(index, { name: field.name, role: UserRole.Admin }));
    } else {
      fields.forEach((field, index) => update(index, { name: field.name, role: NoChange.NoChange }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardRole]);

  function transformSubmitData(data: MultiEditUser) {
    const userBatch: any = [];
    const editedAppList: any = [];
    const populateEditedAppList = () => {
      data.app_roles
        .filter((role) => role.role !== NoChange.NoChange)
        .map((role) => editedAppList.push({ name: role.name, role: role.role }));
    };
    populateEditedAppList();
    const populateUserBatch = () => {
      data.userIds.map((userId, index) =>
        userBatch.push({
          email: data.userEmails[index],
          name: data.userNames[index],
          id: userId,
          app_roles: editedAppList,
        }),
      );
    };
    populateUserBatch();

    return userBatch;
  }

  const handleSave = async () => {
    try {
      await handleSubmit((data) => {
        const transformedData = transformSubmitData(data);
        // For now, this function loops over users and sends multiple individual PUT requests.
        // Once the JSON payload schema issue is solved, we can test the batch edit
        // with the below command
        // (remember to also uncomment the import on top of this file)
        // return editMultipleUsers(transformedData);
        transformedData.forEach((userId: User) => {
          return editUserById(userId);
        });
      })();
    } catch (e: any) {
      // Continue
    }

    onClose();
    clearSelectedUser();
  };

  const handleClose = () => {
    onClose();
    clearSelectedUser();
  };

  const deleteModalOpen = () => setDeleteModal(true);
  const deleteModalClose = () => setDeleteModal(false);

  const handleDelete = () => {
    userIdsList.forEach((id: string) => {
      deleteUserById(id);
    });

    clearSelectedUser();
    setUserId(null);
    handleClose();
    deleteModalClose();
  };

  // Button with delete option.
  const buttonDelete = () => {
    return (
      !userIdsList.includes(currentUser?.id as string) && (
        <button
          onClick={deleteModalOpen}
          type="button"
          className="mb-4 sm:mb-0 inline-flex items-center px-4 py-2 text-sm font-medium rounded-md text-red-700 bg-red-50 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          <TrashIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
          Delete {userIds.length} users
        </button>
      )
    );
  };
  return (
    <>
      <Modal
        onClose={handleClose}
        open={open}
        onSave={handleSave}
        isLoading={userModalLoading}
        leftActions={<>{buttonDelete()}</>}
        useCancelButton
      >
        <div className="bg-white px-4">
          <div className="space-y-10 divide-y divide-gray-200">
            <div>
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">Edit {userIds.length} users</h3>
              </div>

              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                {isAdmin && (
                  <>
                    <div className="sm:col-span-3">
                      {fields
                        .filter((field) => field.name === 'dashboard')
                        .map((item, index) => (
                          <Select
                            key={item.name}
                            control={control}
                            name={`app_roles.${index}.role`}
                            label="Role"
                            options={[
                              { value: UserRole.User, name: 'User' },
                              { value: UserRole.Admin, name: 'Admin' },
                            ]}
                          />
                        ))}
                    </div>
                    <div className="sm:col-span-3 opacity-40 cursor-default pointer-events-none select-none">
                      <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                        Status
                      </label>
                      <div className="mt-1">
                        <select
                          id="status"
                          name="status"
                          className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        >
                          <option>Active</option>
                          <option>Inactive</option>
                          <option>Banned</option>
                        </select>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {isAdmin && !userModalLoading && (
              <div>
                <div className="mt-8">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">App Access</h3>
                </div>

                {isAdminRoleSelected && (
                  <div className="sm:col-span-6">
                    <Banner
                      title="Admin users automatically have admin-level access to all apps."
                      titleSm="Admin user"
                    />
                  </div>
                )}

                {!isAdminRoleSelected && (
                  <div>
                    <div className="flow-root mt-6">
                      <ul className="-my-5 divide-y divide-gray-200">
                        {fields.map((item, index) => {
                          if (item.name != null && HIDDEN_APPS.indexOf(item.name) !== -1) {
                            return null;
                          }

                          return (
                            <li className="py-4" key={item.name}>
                              <div className="flex items-center space-x-4">
                                <div className="flex-shrink-0 flex-1 flex items-center">
                                  <img
                                    className="h-10 w-10 rounded-md overflow-hidden"
                                    src={_.find(apps, ['slug', item.name!])?.assetSrc}
                                    alt={item.name ?? 'Image'}
                                  />
                                  <h3 className="ml-4 text-md leading-6 font-medium text-gray-900">
                                    {_.find(apps, ['slug', item.name!])?.name}
                                  </h3>
                                </div>
                                <div>
                                  <Select
                                    key={item.id}
                                    control={control}
                                    name={`app_roles.${index}.role`}
                                    disabled={isAdminRoleSelected}
                                    options={[
                                      { value: NoChange.NoChange, name: '...' },
                                      { value: UserRole.NoAccess, name: 'No Access' },
                                      { value: UserRole.User, name: 'User' },
                                      { value: UserRole.Admin, name: 'Admin' },
                                    ]}
                                  />
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </Modal>
      <ConfirmationModal
        onDeleteAction={handleDelete}
        open={deleteModal}
        onClose={deleteModalClose}
        title="Delete user"
        body={`You are about to delete ${userIds.length} users. Are sure you want to delete them? All of the user data will be permanently removed. This action cannot be undone.`}
      />
    </>
  );
};

import { createApiAction } from 'src/services/api';
import { SuccessAction } from 'src/services/api/redux/types';

export enum AuthActionTypes {
  SIGN_IN_START = 'auth/sign_in_start',
  SIGN_IN_SUCCESS = 'auth/sign_in_success',
  SIGN_IN_FAILURE = 'auth/sign_in_failure',
  SIGN_OUT = 'auth/SIGN_OUT',
  UPDATE_AUTH_USER = 'auth/update_auth_user',
  REGISTRATION_START = 'auth/registration_start',
  REGISTRATION_FAILURE = 'auth/registration_failure',
}

const signOutAction = (): SuccessAction => ({
  type: AuthActionTypes.SIGN_OUT,
  payload: null,
});

export const signIn = (params: string) =>
  createApiAction(
    {
      path: `/hydra/callback${params}`,
      method: 'GET',
    },
    [AuthActionTypes.SIGN_IN_START, AuthActionTypes.SIGN_IN_SUCCESS, AuthActionTypes.SIGN_IN_FAILURE],
  );

export function signOut() {
  return (dispatch: any) => {
    dispatch(signOutAction());
  };
}

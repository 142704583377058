/* eslint-disable react-hooks/exhaustive-deps */
/**
 * This page shows system information, in particular resource usage (cpu,
 * memory, disk).
 *
 * This page is only available for admin users.
 */
import React, { useEffect } from 'react';
import { ResourceCard } from './components/ResourceCard';
import { useResources } from 'src/services/resources';
import { useApps } from 'src/services/apps';

const metrics = [
  {
    id: 'memory',
    title: 'Memory',
  },
  {
    id: 'cpu',
    title: 'CPU',
  },
  {
    id: 'disk',
    title: 'Disk',
  },
];

export const ResourcesDashboard: React.FC = () => {
  const { resources, loadResources } = useResources();
  const { apps, loadApps } = useApps();
  window.console.log(resources);

  useEffect(() => {
    loadResources();
    loadApps();
  }, []);

  const grafanaUrl = apps
    .filter((app) => app.slug === 'monitoring')
    .map((app) => app.url)
    .join();

  return (
    <div className="relative">
      <div className="max-w-7xl mx-auto py-4 px-3 sm:px-6 lg:px-8 h-full flex-grow">
        <div className="pb-5 mt-6 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
          <h1 className="text-3xl leading-6 font-bold text-gray-900">System resources</h1>
        </div>
        {resources && (
          <>
            <div className="flex flex-col">
              <div className="-my-2 sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="max-w-7xl mx-auto py-4 px-3 sm:px-6 lg:px-8 h-full flex-grow">
                    <div className=" mt-5 grid grid-cols-1 md:grid-cols-2 md:gap-4 lg:grid-cols-3 mb-10">
                      {metrics.map((metric) => (
                        <ResourceCard key={metric.id} metric={metric} resources={resources} />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p>
              For further details and system resource history, see&nbsp;
              <a className="text-primary-700 hover:underline" href={grafanaUrl}>
                Grafana
              </a>
              .
            </p>
          </>
        )}
        {!resources && (
          <p>
            Monitoring of system resources is not available. Perhaps the monitoring app is not installed or not
            functioning properly.
          </p>
        )}
      </div>
    </div>
  );
};

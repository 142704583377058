/* eslint-disable react-hooks/exhaustive-deps */
/**
 * This page shows all the applications and their status in a table.
 *
 * This page is only available for Admin users.
 */
import React, { useState, useCallback, useMemo, useEffect } from 'react';
// import { useNavigate } from 'react-router';
import { SearchIcon } from '@heroicons/react/solid';
import { showToast, ToastType } from 'src/common/util/show-toast';
import _, { debounce } from 'lodash';
import { Table } from 'src/components';
import { App, AppStatusEnum, useApps } from 'src/services/apps';
// import { AppInstallModal } from './components';
import { getConstForStatus } from './consts';

export const Apps: React.FC = () => {
  // If you want to enable the App Install button again, uncomment this:
  // const [installModalOpen, setInstallModalOpen] = useState(false);
  // const [appSlug, setAppSlug] = useState(null);

  const [search, setSearch] = useState('');
  const { apps, appTableLoading, loadApps } = useApps();

  const handleSearch = useCallback((event: any) => {
    setSearch(event.target.value);
  }, []);

  const debouncedSearch = useCallback(debounce(handleSearch, 250), []);

  useEffect(() => {
    loadApps();

    return () => {
      debouncedSearch.cancel();
    };
  }, []);

  const filterSearch = useMemo(() => {
    return _.filter(apps, (item) => item.name?.toLowerCase().includes(search.toLowerCase()));
  }, [apps, search]);

  const columns: any = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: (e: any) => {
        const app = e.cell.row.original as App;
        return (
          <div className="flex items-center">
            <div className="flex-shrink-0 h-10 w-10">
              <img className="h-10 w-10 rounded-md overflow-hidden" src={app.assetSrc} alt={app.name} />
            </div>
            <div className="ml-4">
              <div className="text-sm font-medium text-gray-900">{app.name}</div>
            </div>
          </div>
        );
      },
      width: 'auto',
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: (e: any) => {
        const status = e.cell.row.original.status as AppStatusEnum;
        return (
          <div className="flex items-center">
            {status === AppStatusEnum.External ? (
              <div
                className={`flex-shrink-0 h-4 w-4 rounded-full bg-transparent border-2 border-${getConstForStatus(
                  status,
                  'colorClass',
                )}`}
              />
            ) : (
              <div className={`flex-shrink-0 h-4 w-4 rounded-full bg-${getConstForStatus(status, 'colorClass')}`} />
            )}

            {status === AppStatusEnum.Installing ? (
              <div
                className={`ml-2 cursor-pointer text-sm text-${getConstForStatus(status, 'colorClass')}`}
                onClick={() => showToast('Installing an app can take up to 10 minutes.', ToastType.Success)}
              >
                {status}
              </div>
            ) : (
              <div className={`ml-2 text-sm text-${getConstForStatus(status, 'colorClass')}`}>{status}</div>
            )}
          </div>
        );
      },
      width: 'auto',
    },
    // If you want to enable the App Install button again, uncomment this:
    //
    // We need to implement installation and configuration in the back-end to be
    // able to use those buttons.
    // {
    //   Header: ' ',
    //   Cell: (e: any) => {
    //     const navigate = useNavigate();
    //     const appStatus = e.cell.row.original.status as AppStatusEnum;
    //     if (appStatus === AppStatusEnum.Installing) {
    //       return null;
    //     }
    //     const { slug } = e.cell.row.original;
    //     let buttonFunction = () => navigate(`/apps/${slug}`);
    //     if (appStatus === AppStatusEnum.NotInstalled) {
    //       buttonFunction = () => {
    //         setAppSlug(slug);
    //         setInstallModalOpen(true);
    //       };
    //     }
    //     return (
    //       <div className="text-right opacity-0 group-hover:opacity-100 transition-opacity">
    //         <button
    //           onClick={buttonFunction}
    //           type="button"
    //           className="inline-flex items-center px-4 py-2 border border-gray-200 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
    //         >
    //           {getConstForStatus(appStatus, 'buttonIcon')}
    //           {getConstForStatus(appStatus, 'buttonTitle')}
    //         </button>
    //       </div>
    //     );
    //   },
    //   width: 'auto',
    // },
  ];

  return (
    <div className="relative">
      <div className="max-w-7xl mx-auto py-4 px-3 sm:px-6 lg:px-8 h-full flex-grow">
        <div className="pb-5 mt-6 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
          <h1 className="text-3xl leading-6 font-bold text-gray-900">Apps</h1>
        </div>

        <div className="flex justify-between w-100 my-3 items-center">
          <div className="flex items-center">
            <div className="inline-block">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 sr-only">
                Search candidates
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <div className="relative flex items-stretch flex-grow focus-within:z-10">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    className="focus:ring-primary-dark focus:border-primary-dark block w-full rounded-md pl-10 sm:text-sm border-gray-200"
                    placeholder="Search Apps"
                    onChange={handleSearch}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col">
          <div className="-my-2 sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow border-b border-gray-200 sm:rounded-lg">
                <Table
                  data={_.filter(filterSearch, (app) => app.slug !== 'dashboard') as any}
                  columns={columns}
                  loading={appTableLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        // If you want to enable the App Install button again, uncomment this:
        //
        // installModalOpen && (
        //   <AppInstallModal appSlug={appSlug} onClose={() => setInstallModalOpen(false)} open={installModalOpen} />
        // )
      }
    </div>
  );
};

import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { isValid } from './services/api';
import { configureStore } from './redux';
import { AuthActionTypes } from './services/auth/redux';
import App from './App';
import './index.css';

const { store, persistor } = configureStore();

axios.interceptors.request.use(
  (config) => {
    const { auth } = store.getState();
    if (isValid(auth)) {
      config.headers.Authorization = `Bearer ${auth.token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// when we receive 401 error, we sign out the logged in user
axios.interceptors.response.use(
  (response: any) => response,
  (error: any) => {
    if (error.response !== undefined && error.response.status === 401) {
      store.dispatch({ type: AuthActionTypes.SIGN_OUT, payload: error });
    }

    return Promise.reject(error);
  },
);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

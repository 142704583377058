// This file is still being used in the AppSingle.tsx file
// to populate the single app card with URLs and images.
// Single App is not an active view at the moment,
// so automating this is not a priority at the moment.
// once we activate single app views, we will need to use the API call
// to populate the AppSingle card with this info.
// See UserModal.tsx for inspiration, search for initialAppRoleLatest()

// import { UserRole } from 'src/services/users';

export const appAccessList = [
  {
    name: 'hedgedoc',
    image: '/assets/hedgedoc.svg',
    label: 'HedgeDoc',
    documentationUrl: 'https://docs.hedgedoc.org/',
  },
  {
    name: 'wekan',
    image: '/assets/wekan.svg',
    label: 'Wekan',
    documentationUrl: 'https://github.com/wekan/wekan/wiki',
  },
  {
    name: 'wordpress',
    image: '/assets/wordpress.svg',
    label: 'Wordpress',
    documentationUrl: 'https://wordpress.org/support/',
  },
  {
    name: 'nextcloud',
    image: '/assets/nextcloud.svg',
    label: 'Nextcloud',
    documentationUrl: 'https://docs.nextcloud.com/server/latest/user_manual/en/',
  },
  {
    name: 'zulip',
    image: '/assets/zulip.svg',
    label: 'Zulip',
    documentationUrl: 'https://docs.zulip.com/help/',
  },
  {
    name: 'monitoring',
    image: '/assets/monitoring.svg',
    label: 'Monitoring',
    documentationUrl: 'https://grafana.com/docs/',
  },
];

export const allAppAccessList = [
  {
    name: 'dashboard',
    image: '/assets/logo-small.svg',
    label: 'Dashboard',
  },
  ...appAccessList,
];

// export const initialAppRoles = [
//   {
//     name: 'dashboard',
//     role: UserRole.User,
//   },
//   {
//     name: 'hedgedoc',
//     role: UserRole.User,
//   },
//   {
//     name: 'wekan',
//     role: UserRole.User,
//   },
//   {
//     name: 'wordpress',
//     role: UserRole.User,
//   },
//   {
//     name: 'nextcloud',
//     role: UserRole.User,
//   },
//   {
//     name: 'zulip',
//     role: UserRole.User,
//   },
//   {
//     name: 'monitoring',
//     role: UserRole.NoAccess,
//   },
// ];

// export const initialUserForm = {
//   id: '',
//   name: '',
//   email: '',
//   app_roles: initialAppRoles,
//   status: '',
// };

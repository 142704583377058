import _ from 'lodash';
import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Switch } from 'src/components/Form';
import { App, useApps } from 'src/services/apps';

export const GeneralTab = () => {
  const { app, editApp } = useApps();
  const { control, reset, handleSubmit } = useForm<App>({
    defaultValues: { automaticUpdates: false },
  });

  useEffect(() => {
    if (!_.isEmpty(app)) {
      reset(app);
    }

    return () => {
      reset({ automaticUpdates: false });
    };
  }, [app, reset]);

  const onSubmit: SubmitHandler<App> = (data) => {
    try {
      editApp(data);
    } catch (e: any) {
      // continue
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="py-4">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-2">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Automatic updates</h3>
            <p className="mt-1 text-sm text-gray-500">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla et nibh sit amet mauris faucibus molestie
              gravida at orci.
            </p>
          </div>
          <div className="my-auto ml-auto">
            <Switch control={control} name="automaticUpdates" />
          </div>
        </div>
      </div>
      <div className="py-3 sm:flex">
        <div className="ml-auto sm:flex sm:flex-row-reverse">
          <button
            type="submit"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Save changes
          </button>
        </div>
      </div>
    </form>
  );
};

import React, { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import { ModalProps } from './types';

export const Modal: React.FC<ModalProps> = ({
  open,
  onClose,
  onSave,
  saveButtonTitle = 'Save Changes',
  children,
  title,
  img,
  useCancelButton = false,
  cancelButtonTitle = 'Cancel',
  isLoading = false,
  leftActions = <></>,
  saveButtonDisabled = false,
}) => {
  const cancelButtonRef = useRef(null);
  const saveButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={() => {}}>
        {isLoading && (
          <Dialog.Overlay className="isloading inset-0 bg-gray-400 z-20 bg-opacity-75 transition-opacity absolute flex justify-center items-center">
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
          </Dialog.Overlay>
        )}
        <div className="pt-4 h-full px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          {/* <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span> */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="w-full h-full flex justify-center items-center">
              <div className="bg-white h-4/5 overflow-auto flex flex-col rounded-lg text-left shadow-xl transform transition-all sm:max-w-2xl sm:w-full">
                {!useCancelButton && (
                  <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:items-center sm:justify-between">
                    <div className="flex items-center">
                      <img className="rounded-md" width={32} src={img} alt={title} />
                      <span className="ml-2 uppercase font-bold">{title}</span>
                    </div>
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-gray-200 p-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={onClose}
                      ref={cancelButtonRef}
                    >
                      <XIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </button>
                  </div>
                )}

                <div className="bg-white px-4 p-6 relative overflow-y-auto">{children}</div>

                {onSave && (
                  <div className="bg-gray-50 px-4 py-5 flex flex-shrink-0 w-full border-0 border-t-2 border-gray-300">
                    <div className="mr-auto sm:flex gap-2">{leftActions}</div>
                    <div className="ml-auto sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm ${
                          saveButtonDisabled ? 'opacity-50' : ''
                        }`}
                        onClick={onSave}
                        ref={saveButtonRef}
                        disabled={saveButtonDisabled}
                      >
                        {saveButtonTitle}
                      </button>
                      {useCancelButton && (
                        <button
                          type="button"
                          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-200 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                          onClick={onClose}
                          ref={cancelButtonRef}
                        >
                          {cancelButtonTitle}
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { TrashIcon, KeyIcon, QrcodeIcon } from '@heroicons/react/outline';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { Banner, Modal, ConfirmationModal, InfoModal } from 'src/components';
import { Input, Select } from 'src/components/Form';
import { User, UserRole, useUsers } from 'src/services/users';
import { useAuth } from 'src/services/auth';
import { AppStatusEnum } from 'src/services/apps/types';

// Tagging component, work in progress. commented out for the time being
// import { Tags } from 'src/components/Tags';

import { HIDDEN_APPS } from 'src/modules/dashboard/consts';
// import { initialUserForm } from './consts';

import { UserModalProps } from './types';

export const UserModal = ({ open, onClose, userId, setUserId, apps }: UserModalProps) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [passwordLinkModal, setPasswordLinkModal] = useState(false);
  const [totpModal, setTotpModal] = useState(false);
  const [webAuthnModal, setWebAuthnModal] = useState(false);
  const [isAdminRoleSelected, setAdminRoleSelected] = useState(true);
  const [isPersonalModal, setPersonalModal] = useState(false);
  const {
    user,
    recoveryLink,
    loadUser,
    loadPersonalInfo,
    editUserById,
    editPersonalInfo,
    createNewUser,
    userModalLoading,
    deleteUserById,
    getRecoveryLinkUserById,
    clearSelectedUser,
    resetTotp,
    resetWebAuthn,
  } = useUsers();
  const { currentUser, isAdmin } = useAuth();

  // build initial app role list programmatially,
  // pulling from list of installed apps on cluster
  interface AppListInt {
    name: string;
    role: UserRole;
  }
  const appList: AppListInt[] = [];
  const initialAppRoleLatest = () => {
    apps
      .filter((app) => app.status !== AppStatusEnum.NotInstalled)
      .map((app) =>
        app.slug === 'monitoring'
          ? appList.push({ name: app.slug, role: UserRole.NoAccess })
          : appList.push({ name: app.slug, role: UserRole.User }),
      );
  };
  initialAppRoleLatest();

  const initialUserForm = {
    id: '',
    name: '',
    email: '',
    app_roles: appList,
    status: '',
  };

  // populate the initial "New User" window with installed apps and default roles
  const { control, reset, handleSubmit } = useForm<User>({
    defaultValues: initialUserForm,
  });

  const { fields, update } = useFieldArray({
    control,
    name: 'app_roles',
  });

  useEffect(() => {
    if (userId) {
      const currentUserId = currentUser?.id;
      if (currentUserId === userId) {
        setPersonalModal(true);
        loadPersonalInfo();
      } else {
        loadUser(userId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, open]);

  useEffect(() => {
    if (!_.isEmpty(user)) {
      reset(user);
    }

    // return () => {
    //   reset(initialUserForm);
    // };
  }, [user, reset, open]);

  let dashboardIndex = 0;
  apps.forEach((app, index) => {
    if (app.name === 'dashboard') {
      dashboardIndex = index;
    }
  });
  const dashboardRole = useWatch({
    control,
    name: `app_roles.${dashboardIndex}.role`,
  });

  useEffect(() => {
    const isAdminDashboardRoleSelected = dashboardRole === UserRole.Admin;
    setAdminRoleSelected(isAdminDashboardRoleSelected);
    if (isAdminDashboardRoleSelected) {
      fields.forEach((field, index) => update(index, { name: field.name, role: UserRole.Admin }));
    } else {
      fields.forEach((field, index) => update(index, { name: field.name, role: UserRole.User }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardRole]);

  const handleSave = async () => {
    try {
      if (isPersonalModal) {
        await handleSubmit((data) => editPersonalInfo(data))();
      } else if (userId) {
        await handleSubmit((data) => editUserById(data))();
      } else {
        await handleSubmit((data) => createNewUser(data))();
      }
    } catch (e: any) {
      // Continue
    }

    onClose();
    clearSelectedUser();
    setUserId(null);
  };

  const handleClose = () => {
    onClose();
    clearSelectedUser();
    setUserId(null);
  };

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter' || e.key === 'NumpadEnter') {
      handleSave();
    }
  };

  const deleteModalOpen = () => setDeleteModal(true);
  const deleteModalClose = () => setDeleteModal(false);

  const passwordLinkModalOpen = () => {
    if (userId) {
      getRecoveryLinkUserById(userId);
    }
    setPasswordLinkModal(true);
  };

  const passwordLinkModalClose = () => setPasswordLinkModal(false);

  const totpModalOpen = () => {
    if (userId) {
      resetTotp(userId);
      clearSelectedUser();
      setUserId(userId);
    }
    setTotpModal(true);
  };

  const totpModalClose = () => setTotpModal(false);

  const webAuthnModalOpen = () => {
    if (userId) {
      resetWebAuthn(userId);
      clearSelectedUser();
      setUserId(userId);
    }
    setWebAuthnModal(true);
  };

  const webAuthnModalClose = () => setWebAuthnModal(false);

  const handleDelete = () => {
    if (userId) {
      deleteUserById(userId);
    }

    clearSelectedUser();
    setUserId(null);
    handleClose();
    deleteModalClose();
  };

  // Button with delete option.
  const buttonDelete = () => {
    return (
      userId &&
      user.email !== currentUser?.email && (
        <button
          onClick={deleteModalOpen}
          type="button"
          className="mb-4 sm:mb-0 inline-flex items-center px-4 py-2 text-sm font-medium rounded-md text-red-700 bg-red-50 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          Delete user
          <TrashIcon className="-mr-0.5 ml-2 h-4 w-4" aria-hidden="true" />
        </button>
      )
    );
  };

  // Button to generate password link
  const buttonPasswordLink = () => {
    return (
      userId &&
      isAdmin && (
        <button
          onClick={passwordLinkModalOpen}
          type="button"
          className="mb-4 sm:mb-0 inline-flex items-center px-4 py-2 text-sm
  font-medium rounded-md text-primary-700 bg-primary-100 hover:bg-primary-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-700"
        >
          Password Link
          <KeyIcon className="-mr-0.5 ml-2 h-4 w-4" aria-hidden="true" />
        </button>
      )
    );
  };

  // Button to reset TOTP
  const buttonTotp = () => {
    return (
      userId &&
      isAdmin &&
      user.totp && (
        <button
          onClick={totpModalOpen}
          type="button"
          className="mb-4 sm:mb-0 inline-flex items-center px-4 py-2 text-sm
  font-medium rounded-md text-primary-700 bg-primary-100 hover:bg-primary-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-700"
        >
          Reset TOTP
          <QrcodeIcon className="-mr-0.5 ml-2 h-4 w-4" aria-hidden="true" />
        </button>
      )
    );
  };

  // Button to reset WebAuthn
  const buttonWebAuthn = () => {
    return (
      userId &&
      isAdmin &&
      user.webauthn && (
        <button
          onClick={webAuthnModalOpen}
          type="button"
          className="mb-4 sm:mb-0 inline-flex items-center px-4 py-2 text-sm
  font-medium rounded-md text-primary-700 bg-primary-100 hover:bg-primary-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-700"
        >
          Reset WebAuthn
          <QrcodeIcon className="-mr-0.5 ml-2 h-4 w-4" aria-hidden="true" />
        </button>
      )
    );
  };

  return (
    <>
      <Modal onClose={handleClose} open={open} onSave={handleSave} isLoading={userModalLoading} useCancelButton>
        <div className="bg-white px-4">
          <div className="space-y-10 divide-y divide-gray-200">
            <div>
              <div className="py-4">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {userId ? <span>Edit {user.email}</span> : 'Add new user'}
                </h3>
              </div>

              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <Input control={control} name="name" label="Name" onKeyPress={handleKeyPress} required={false} />
                </div>

                <div className="sm:col-span-3">
                  <Input
                    control={control}
                    name="email"
                    label="Email"
                    type="email"
                    onKeyPress={handleKeyPress}
                    required
                  />
                </div>
                {isAdmin && (
                  <>
                    <div className="sm:col-span-3">
                      {fields
                        .filter((field) => field.name === 'dashboard')
                        .map((item, index) => (
                          <Select
                            key={item.name}
                            control={control}
                            name={`app_roles.${index}.role`}
                            label="Role"
                            options={[
                              { value: UserRole.User, name: 'User' },
                              { value: UserRole.Admin, name: 'Admin' },
                            ]}
                          />
                        ))}
                    </div>
                    <div className="sm:col-span-3 opacity-40 cursor-default pointer-events-none select-none">
                      <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                        Status
                      </label>
                      <div className="mt-1">
                        <select
                          id="status"
                          name="status"
                          className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        >
                          <option>Active</option>
                          <option>Inactive</option>
                          <option>Banned</option>
                        </select>
                      </div>
                    </div>
                    {userId && (
                      <div className="sm:col-span-6">
                        <p className="text-gray-400 text-xs">User ID: {userId}</p>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            {isAdmin && !userModalLoading && (
              <div>
                <div className="mt-8">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">App Access</h3>
                </div>

                {isAdminRoleSelected && (
                  <div className="sm:col-span-6 py-12">
                    <Banner
                      title="Admin users automatically have admin-level access to all apps."
                      titleSm="Admin user"
                    />
                  </div>
                )}

                {!isAdminRoleSelected && (
                  <div>
                    <div className="flow-root mt-6">
                      <ul className="-my-5 divide-y divide-gray-200">
                        {fields.map((item, index) => {
                          if (item.name != null && HIDDEN_APPS.indexOf(item.name) !== -1) {
                            return null;
                          }

                          return (
                            <li className="py-4" key={item.name}>
                              <div className="flex items-center space-x-4">
                                <div className="flex-shrink-0 flex-1 flex items-center">
                                  <img
                                    className="h-10 w-10 rounded-md overflow-hidden"
                                    src={_.find(apps, ['slug', item.name!])?.assetSrc}
                                    alt={item.name ?? 'Image'}
                                  />
                                  <h3 className="ml-4 text-md leading-6 font-medium text-gray-900">
                                    {_.find(apps, ['slug', item.name!])?.name}
                                  </h3>
                                </div>
                                <div>
                                  <Select
                                    key={item.id}
                                    control={control}
                                    name={`app_roles.${index}.role`}
                                    disabled={isAdminRoleSelected}
                                    options={[
                                      { value: UserRole.NoAccess, name: 'No Access' },
                                      { value: UserRole.User, name: 'User' },
                                      { value: UserRole.Admin, name: 'Admin' },
                                    ]}
                                  />
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            )}
            {/* This is the Tag section */}
            {/* {isAdmin && !userModalLoading && (
              <div>
                <div className="mt-8">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Tags</h3>
                </div>
                <Tags />
              </div>
            )} */}
            {isAdmin && userId && !userModalLoading && (
              <div>
                <div className="mt-8">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">User Access</h3>
                </div>
                <div>
                  <div className="flow-root mt-6">
                    <ul className="-my-5 divide-y divide-gray-200">
                      <li className="py-4">
                        <div className="flex items-center justify-between">
                          <p className="leading-6 text-sm  text-gray-500">
                            Generate password reset link for {user.email}
                          </p>
                          {buttonPasswordLink()}
                        </div>
                      </li>
                      <li className="py-4">
                        <div className="flex items-center justify-between">
                          <p className="leading-6 text-sm  text-gray-500">Reset TOTP</p>
                          {user.totp ? (
                            <>{buttonTotp()}</>
                          ) : (
                            <p
                              className="leading-6 text-sm  text-gray-400 mb-4 sm:mb-0 inline-flex items-center px-4 py-2
  font-medium rounded-md bg-gray-50"
                            >
                              No TOTP enrolled
                            </p>
                          )}
                        </div>
                      </li>
                      <li className="py-4">
                        <div className="flex items-center justify-between">
                          <p className="leading-6 text-sm  text-gray-500">Reset WebAuthn</p>
                          {user.webauthn ? (
                            <>{buttonWebAuthn()}</>
                          ) : (
                            <p
                              className="leading-6 text-sm  text-gray-400 mb-4 sm:mb-0 inline-flex items-center px-4 py-2
  font-medium rounded-md bg-gray-50"
                            >
                              No WebAuthn registered
                            </p>
                          )}
                        </div>
                      </li>

                      {user.email !== currentUser?.email && (
                        <li className="py-4">
                          <div className="flex items-center justify-between">
                            <p className="leading-6 text-sm  text-gray-500">Delete {user.email}</p>
                            {buttonDelete()}
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
      <ConfirmationModal
        onDeleteAction={handleDelete}
        open={deleteModal}
        onClose={deleteModalClose}
        title="Delete user"
        body="Are you sure you want to delete this user? All of the user data will be permanently removed. This action cannot be undone."
      />
      <InfoModal
        open={passwordLinkModal}
        onClose={passwordLinkModalClose}
        title="Password reset link"
        body="Below is a password reset link. Copy this link and send to the user to reset the password without using e-mail"
        dynamicData={recoveryLink}
      />
      <InfoModal
        open={totpModal}
        onClose={totpModalClose}
        title="Reset TOTP"
        body="You have successfully removed the user's TOTP registration."
        dynamicData=""
      />
      <InfoModal
        open={webAuthnModal}
        onClose={webAuthnModalClose}
        title="Reset WebAuthn"
        body="You have successfully removed the user's WebAuthn registration."
        dynamicData=""
      />
    </>
  );
};
